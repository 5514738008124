import React, { useState } from "react"
import axios from "axios"
import images from "../../assets/images"
import "./Singleformations.css"
import PopupSingleFormation from "./PopupSingleFormation"
import {
  generateTrainingSlug,
  mediaUrl,
  showLocality,
  showLocality2,
  slugify,
  stripTags,
  trainingDuration,
  validateEmail,
  validatePhone,
} from "../../services/helpers"
import { Link } from "gatsby"
import Moment from "react-moment"
import "moment/locale/fr"
import Carousel from "react-elastic-carousel"
import ProgressiveImage from "react-progressive-graceful-image"
import clip from "text-clipper"
import useWindowSize from "../../services/useWindowSize"

var md = require("markdown-it")({
  html: true,
  xhtmlOut: true,
  breaks: true,
})

function Singleformations(props) {
  const { item, relatedTrainings, levels, trainingLink } = props
  console.log('item', item);
  const [isShowPopUp, togglePopUp] = useState(false)
  const [isShowLocalities, toggleLocalities] = useState(false)
  const [isShowTypes, toggleTypes] = useState(false)
  const [isShowDevices, toggleDevices] = useState(false)
  const [isShowPublicAdmis, togglePublicAdmis] = useState(false)
  const [customerSubject, setCustomerSubject] = useState(
    "Demande d'information"
  )
  const [customerSituation, setCustomerSituation] = useState("Etudiant")
  const [customerLevel, setCustomerLevel] = useState("BP - BAC")
  const [customerCity, setCustomerCity] = useState("")
  const [customerLastname, setCustomerLastname] = useState("")
  const [customerFirstname, setCustomerFirstname] = useState("")
  const [customerPhone, setCustomerPhone] = useState("")
  const [customerEmail, setCustomerEmail] = useState("")
  const [customerContent, setCustomerContent] = useState("")
  const [customerAttachment, setcustomerAttachment] = useState([])
  const [customerRequestType, setCustomerRequestType] = useState("Informations")
  const [validPhone, setValidPhone] = useState(true)
  const [validEmail, setValidEmail] = useState(true)
  const [token, setToken] = useState("")

  const { width } = useWindowSize()

  const handleDropdownFilter = (el, filter) => {
    if (filter === "situation") {
      setCustomerSituation(el.name)
    }
    if (filter === "level") {
      setCustomerLevel(el.name)
    }
  }

  /* to manage attachment upload */
  const fileAttachment = []
  const handleUploadAttachement = el => {
    if (el.currentTarget.files) {
      const currentFile = el.currentTarget.files[0]
      convertFileToBase64(currentFile).then(data => {
        fileAttachment.push({
          "Content-type": currentFile.type,
          Filename: currentFile.name,
          content: data,
        })
        setcustomerAttachment(fileAttachment)
      })
    }
  }

  const convertFileToBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () =>
        resolve(reader.result.toString("base64").replace(/^data:(.*,)?/, ""))
      reader.onerror = error => reject(error)
    })
  }

  const handleFilter = el => {
    const { value, name } = el.target
    name === "customer_situation" && setCustomerSituation(value)
    name === "customer_level" && setCustomerLevel(value)
    name === "customer_city" && setCustomerCity(value)
    name === "customer_lastname" && setCustomerLastname(value)
    name === "customer_firstname" && setCustomerFirstname(value)
    name === "customer_content" && setCustomerContent(value)
    if (name === "customer_phone") {
      setCustomerPhone(value)
      if (validatePhone(value)) setValidPhone(true)
      else setValidPhone(false)
    }
    if (name === "customer_email") {
      setCustomerEmail(value)
      if (validateEmail(value)) setValidEmail(true)
      else setValidEmail(false)
    }
  }

  const sendEmail = async e => {
    e.preventDefault()

    // Validate recaptcha
    const validated = await triggerValidateCaptchaFunction({ token: token })
    if (!validated) return false

    const data = {
      title: item.title,
      contact: item.contact_recrutment_email ||
        "",
      subject: customerSubject,
      situation: customerSituation,
      level: customerLevel,
      lastname: customerLastname,
      firstname: customerFirstname,
      email: customerEmail,
      phone: customerPhone,
      type: customerRequestType,
      link: trainingLink,
      content: customerContent,
      attachment: customerAttachment,
    }
    setToken("")
    return await triggerSendEmailFunction(data)
  }

  const triggerValidateCaptchaFunction = async data => {
    try {
      return await axios.post("/.netlify/functions/validate-recaptcha", data)
    } catch (error) {
      return false
    }
  }

  const triggerSendEmailFunction = async data => {
    try {
      return await axios.post("/.netlify/functions/send-contact-email", data)
    } catch (error) {
      return false
    }
  }

  const closeAllMenu = () => {
    toggleLocalities(false)
    toggleTypes(false)
    toggleDevices(false)
    togglePublicAdmis(false)
  }

  const handleAction = type => {
    let toExternalLink = ""
    let customerSubject = ""
    let customerRequestType = ""

    switch (type) {
      case "information":
        toExternalLink = item.external_link || ""
        customerSubject = "Demande d'information"
        customerRequestType = "S'informer gratuitement"
        break
      case "datesession":
      default:
        toExternalLink = ""
        customerSubject = "Demande date de session"
        customerRequestType = "Demander les dates de sessions"
        break
    }

    if (toExternalLink === "") {
      togglePopUp(!isShowPopUp)
      setCustomerSubject(customerSubject)
      setCustomerRequestType(customerRequestType)
    } else {
      window.open(toExternalLink, "_blank")
    }
  }

  return (
    <div className="single-formation">
      <section className="NavsingleFormation">
        <div className="container container-seformer">
          <ul className="breadcrumb-single-formation">
            <li>
              <Link to="/">Accueil</Link>
            </li>
            <li>
              <img
                src={images.breadcrumbsSeparator}
                alt="icone breadcrumbs separator"
              />
            </li>
            <li>
              <Link to="/annonces/">Annonces</Link>
            </li>
            <li>
              <img
                src={images.breadcrumbsSeparator}
                alt="icone breadcrumbs separator"
              />
            </li>
            <li className="active">{item.title}</li>
          </ul>
        </div>
      </section>
      <section className="btnSinformer sticky-top">
        <button
          className="btnSinformer"
          onClick={() => {
            handleAction("information")
          }}
        >
          S’informer
        </button>
      </section>
      <div className="Single-recherche">
        <section
          className={`${
            width > 991 ? "desktop" : "mobile"
          } sliderecherche mb-5`}
        >
          {width <= 991 && (
            <div className="logoSingleSearch">
              <img
                src={
                  mediaUrl(
                    {
                      url:
                        item?.agency?.logo?.url || item?.info_agency?.logo[0],
                    },
                    null,
                    "resize"
                  ) || images.noimage
                }
                alt={item.agency?.name}
                className="img-fluid showMobile"
              />
            </div>
          )}
          <div className="bgslideMobile">
            <div className="container container-seformer text-center">
              {width > 991 && (
                <div className="logoSingleSearch">
                  <img
                    src={
                      mediaUrl(
                        {
                          url:
                            item?.agency?.logo?.url ||
                            item?.info_agency?.logo[0],
                        },
                        null,
                        "original"
                      ) || images.noimage
                    }
                    alt={item.agency?.name}
                    className="icone-logo"
                  />
                </div>
              )}
              <h1>
                {item.title} <span className="candidat-sexe"> </span>
              </h1>
              <h2>{item.agency?.name}</h2>
            </div>

            <div className="bloc-degrader">
              <div className="container-fluid text-center">
                <div className="listemotscle justify-content-center">
                  <ul>
                    <li className={` ${isShowLocalities ? "show" : ""}`}>
                      <h2>
                        {showLocality(item.region, item.cities[0])}
                        {item.cities.length > 1 && (
                          <span
                            onClick={() => {
                              closeAllMenu()
                              toggleLocalities(!isShowLocalities)
                            }}
                            className="btncollapse"
                          >
                            {" "}
                          </span>
                        )}
                      </h2>
                      {isShowLocalities && (
                        <ul className="sous-menu">
                          {item.cities.map((cityItem, key) => {
                            if (key > 0) {
                              return (
                                <li>{showLocality(item.region, cityItem)}</li>
                              )
                            }
                          })}
                        </ul>
                      )}
                    </li>
                    <li className={` ${isShowTypes ? "show" : ""}`}>
                      <h2>
                        {item.types[0]?.name || "NC"}
                        {item.types.length > 1 && (
                          <span
                            onClick={() => {
                              closeAllMenu()
                              toggleTypes(!isShowTypes)
                            }}
                            className="btncollapse"
                          >
                            {" "}
                          </span>
                        )}
                      </h2>
                      {isShowTypes && (
                        <ul className="sous-menu">
                          {item.types.map((typeItem, key) => {
                            if (key > 0) {
                              return <li>{typeItem.name}</li>
                            }
                          })}
                        </ul>
                      )}
                    </li>
                    <li>
                      <h2>Niveau d'entrée: {item.level?.name || "NC"}</h2>
                    </li>
                    <li>
                      <h2>
                        {trainingDuration(
                          item.training_hours,
                          item.training_days,
                          item.training_months
                        )}
                      </h2>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <ProgressiveImage
            preview={images.imgSlideWebp}
            src={images.imgSlideWebp}
          >
            {src => (
              <div
                className="Singlerechercheblur"
                style={{
                  backgroundImage: `url(${
                    width > 991
                      ? images.slideSingleFormation
                      : images.bgslidesingleFormation
                  })`,
                }}
              />
            )}
          </ProgressiveImage>
        </section>

        <div className="container container-seformer">
          <div className="row">
            <div className="col-md-4">
              {item.public_info && (
                <div className="sidebar mb-5">
                  <div className="logoFormation">
                    <img
                      src={
                        mediaUrl(
                          item.public_info.logo,
                          item.public_info.logo,
                          "resize"
                        ) || images.noimage
                      }
                      alt={item.public_info?.entity}
                      className="icone-logo icone-little-search"
                    />
                  </div>
                  <div className="formation-a-la-une">
                    <ul className="infoFormation">
                      <li className="leformateur">{item.agency?.name}</li>
                      <li className="AdresseFormateur">
                        {item.public_info?.additional_address}
                        {`${
                          item.public_info?.additional_address?.length &&
                          item.public_info?.address?.length
                            ? " - "
                            : ""
                        }`}
                        {item.public_info?.address}{" "}
                        {item.public_info?.city}{" "} 
                        {item.public_info?.region}{" "}
                        {/* {(item.cities && item.cities[0]?.postal_code) || ""}{" "} */}
                        {/* {(item.cities && item.cities[0]?.name) || ""}{" "} */}
                        {/* {item.region?.name} */}
                      </li>
                      {item.public_infos && item.public_infos.contact && (
                        <li className="PhoneFormateur">
                          {item.public_infos?.contact?.phone}
                        </li>
                      )}
                      {/*<li>2 150 Personnes formées par an</li>*/}
                      <li className="ProgrammeFormateur">
                        {item.agency.article_length}{" "}
                        {`Programme${
                          (item.agency.article_length > 1 && "s") || ""
                        }`}
                      </li>
                      {(item.public_info.website && (
                        <li className="SiteWebFormateur">
                          <a
                            href={
                              /http|https/.test(item.public_info.website)
                                ? item.public_info.website
                                : `//${item.public_info.website}`
                            }
                            target="_blank"
                          >
                            {item.public_info.website
                              .replace(/https?:\/\//, "")
                              .replace(/\/$/, "")}
                          </a>
                        </li>
                      )) ||
                        (item.agency && item.agency.siteweb && (
                          <li className="SiteWebFormateur">
                            <a
                              href={
                                /http|https/.test(item.agency.siteweb)
                                  ? item.agency.siteweb
                                  : `//${item.agency.siteweb}`
                              }
                              target="_blank"
                            >
                              {item.agency.siteweb
                                .replace(/https?:\/\//, "")
                                .replace(/\/$/, "")}
                            </a>
                          </li>
                        ))}
                    </ul>
                    <p>{item.public_info?.agence_description}</p>
                    {item.public_info?.logo_help && (
                      <div className="d-flex mt-3 justify-content-center">
                        {item.public_info.logo_help
                          .filter((item, index) => index !== 0)
                          .map((logo_help, key) => (
                            <img
                              key={key}
                              src={
                                mediaUrl(logo_help, null, "resize") ||
                                images.noimage
                              }
                              alt="Region Reunion"
                              className="img-fluid icone-little-search"
                              style={{ maxWidth: "150px", margin: "10px" }}
                            />
                          ))}
                      </div>
                    )}
                    <Link
                      to={`/annonces?agency=${slugify(item.agency.name) || ""}`}
                    >
                      <button className="lecatalogue mt-5 mb-3">
                        Voir le catalogue
                        <br />
                        des formations proposées
                      </button>
                    </Link>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-8 contenu-de-loffre">
              <h2 className="titleetapes">Les objectifs</h2>
              <div
                className="desc-content"
                dangerouslySetInnerHTML={{
                  __html: stripTags(
                    md.render(
                      (item.description &&
                        item.description.replace(/\\n/g, "<br>")) ||
                        ""
                    ),
                    "<p><br><ol><ul><li><strong><b><i><u>"
                  ),
                }}
              />
              {item.additional_description && (
                <h2 className="titleetapes">Le programme</h2>
              )}
              {item.additional_description && (
                <div
                  className="desc-content"
                  dangerouslySetInnerHTML={{
                    __html: stripTags(
                      md.render(
                        (typeof item.additional_description === "string" &&
                          (
                            (item.additional_description &&
                              item.additional_description) ||
                            ""
                          ).replace(/\\n/g, "<br>")) ||
                          ""
                      ),
                      "<p><br><ol><ul><li><strong><b><i><u>"
                    ),
                  }}
                />
              )}
              {item.target_job && (
                <h2 className="titleetapes">Métiers visés</h2>
              )}
              {item.target_job && (
                <div
                  className="desc-content"
                  dangerouslySetInnerHTML={{
                    __html: md.render(
                      (typeof item.target_job === "string" &&
                        ((item.target_job && item.target_job) || "").replace(
                          /\\n/g,
                          "<br>"
                        )) ||
                        ""
                    ),
                  }}
                />
              )}
              <ul className="btn-single">
                <li>
                  <button
                    onClick={() => {
                      handleAction("information")
                    }}
                  >
                    S’informer gratuitement
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => {
                      handleAction("datesession")
                    }}
                  >
                    Demander les
                    <br /> dates de sessions
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {isShowPopUp && (
          <PopupSingleFormation
            item={item}
            agency={item.agency}
            levels={levels}
            customerSituation={customerSituation}
            customerLevel={customerLevel}
            customerCity={customerCity}
            customerLastname={customerLastname}
            customerFirstname={customerFirstname}
            customerPhone={customerPhone}
            customerEmail={customerEmail}
            customerContent={customerContent}
            validPhone={validPhone}
            validEmail={validEmail}
            token={token}
            togglePopUp={() => togglePopUp(false)}
            handleUploadAttachement={handleUploadAttachement}
            handleDropdownFilter={handleDropdownFilter}
            handleFilter={handleFilter}
            setToken={setToken}
            sendEmail={sendEmail}
          />
        )}

        <section className="mt-5 formuniversite py-4">
          <div className="container container-seformer">
            <h2 className="titlebottom mb-5">
              {item.title} -<br className="brshowmobile" /> {item.agency?.name}
            </h2>
            <ul className="infosMetas hideMobile">
              <li>
                <p>
                  <span>Offre déposée </span>{" "}
                  <span className="Textgris">
                    <Moment format="DD MMMM YYYY" locale="fr">
                      {item.published_at}
                    </Moment>
                  </span>
                </p>
                <p className={` ${isShowDevices ? "show" : ""}`}>
                  <span>Finançable par</span>{" "}
                  <span className="Textgris">
                    {item.mobilizable_devices[0]?.name || "NC"}
                  </span>
                  {item.mobilizable_devices.length > 1 && (
                    <span
                      onClick={() => {
                        closeAllMenu()
                        toggleDevices(!isShowDevices)
                      }}
                      className="btncollapse"
                    >
                      {" "}
                    </span>
                  )}
                  {isShowDevices && (
                    <ul className="sous-menu">
                      {item.mobilizable_devices.map((devicItem, key) => {
                        if (key > 0) {
                          return <li>{devicItem.name}</li>
                        }
                      })}
                    </ul>
                  )}
                </p>
              </li>
              <li>
                <p>
                  <span>Diplôme obtenu </span>
                  <span className="Textgris">
                    {item.degree_obtained?.name || "NC"}
                  </span>
                </p>
                <p>
                  <span>Taux de réussite </span>
                  <span className="Textgris">
                    {(item.success_rate && item.success_rate + "%") || "NC"}
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>Référence </span>
                  <span className="Textgris">
                    # {item.reference || item.id}
                  </span>
                </p>
                <p className={` ${isShowPublicAdmis ? "show" : ""}`}>
                  <span>Public admis </span>
                  <span className="Textgris">
                    {item.public_admis[0]?.name || "NC"}
                  </span>
                  {item.public_admis.length > 1 && (
                    <span
                      onClick={() => {
                        closeAllMenu()
                        togglePublicAdmis(!isShowPublicAdmis)
                      }}
                      className="btncollapse"
                    >
                      {" "}
                    </span>
                  )}
                  {isShowPublicAdmis && (
                    <ul className="sous-menu">
                      {item.public_admis.map((publicAdmisItem, key) => {
                        if (key > 0) {
                          return <li>{publicAdmisItem.name}</li>
                        }
                      })}
                    </ul>
                  )}
                </p>
              </li>
            </ul>
            <ul className="infosMetas showMobile">
              <li>
                <p>
                  <span>Offre déposée </span>{" "}
                  <span className="Textgris">
                    <Moment format="DD MMMM YYYY" locale="fr">
                      {item.published_at}
                    </Moment>
                  </span>
                </p>
                <p>
                  <span>Diplôme obtenu </span>
                  <span className="Textgris">
                    {item.degree_obtained?.name || "NC"}
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>Référence </span>
                  <span className="Textgris"># {item.id}</span>
                </p>
                <p className={` ${isShowDevices ? "show" : ""}`}>
                  <span>Finançable par</span>{" "}
                  <span className="Textgris">
                    {item.mobilizable_devices[0]?.name || "NC"}
                  </span>
                  {item.mobilizable_devices.length > 1 && (
                    <span
                      onClick={() => {
                        closeAllMenu()
                        toggleDevices(!isShowDevices)
                      }}
                      className="btncollapse"
                    >
                      {" "}
                    </span>
                  )}
                  {isShowDevices && (
                    <ul className="sous-menu">
                      {item.mobilizable_devices.map((devicItem, key) => {
                        if (key > 0) {
                          return <li>{devicItem.name}</li>
                        }
                      })}
                    </ul>
                  )}
                </p>
              </li>
              <li>
                <p>
                  <span>Taux de réussite </span>
                  <span className="Textgris">
                    {(item.success_rate && item.success_rate + "%") || "NC"}
                  </span>
                </p>
                <p className={` ${isShowPublicAdmis ? "show" : ""}`}>
                  <span>Public admis </span>
                  <span className="Textgris">
                    {item.public_admis[0]?.name || "NC"}
                  </span>
                  {item.public_admis.length > 1 && (
                    <span
                      onClick={() => {
                        closeAllMenu()
                        togglePublicAdmis(!isShowPublicAdmis)
                      }}
                      className="btncollapse"
                    >
                      {" "}
                    </span>
                  )}
                  {isShowPublicAdmis && (
                    <ul className="sous-menu">
                      {item.public_admis.map((publicAdmisItem, key) => {
                        if (key > 0) {
                          return <li>{publicAdmisItem.name}</li>
                        }
                      })}
                    </ul>
                  )}
                </p>
              </li>
            </ul>
          </div>
        </section>

        {relatedTrainings.length > 0 && (
          <section className="section-similaire">
            <div className="pt-5 contenuformsimilaire">
              <div className="container container-seformer">
                <div className="row">
                  <h3 className="text-center title-section mx-auto mb-5 formationSimilaire">
                    FORMATIONS <span> Similaires</span>
                    <img
                      src={images.FormationSimilaires}
                      alt="Formations similaires"
                    />
                  </h3>
                </div>
                <div className="row py-5 desktop">
                  {relatedTrainings.map((item, key) => {
                    return (
                      <div key={"_" + key} className="col-lg-4 mb-5">
                        <Link
                          to={generateTrainingSlug(item)}
                          title={item.title + " - " + item.agency?.name}
                        >
                          <div className="logoFormation">
                            <img
                              src={
                                mediaUrl(
                                  {
                                    url:
                                      item?.agency?.logo?.url ||
                                      item?.info_agency?.logo[0],
                                  },
                                  null,
                                  "resize"
                                ) || images.noimage
                              }
                              alt={item.title + " - " + item.agency?.name}
                              className="icone-logo icone-little-search"
                            />
                          </div>
                          <div className="formation-a-la-une">
                            <h3 className="titleFormation">
                              {clip(item.title, 50, { maxLines: 2 })}
                              <span className="lepost"> </span>
                            </h3>
                            <ul className="infoFormation">
                              <li className="duree">
                                {item.types[0]?.name || "NC"}
                              </li>
                              <li className="lieux">
                                {showLocality2(item.region, item.cities)}
                              </li>
                            </ul>

                            <div className="btn-voir-formation">
                              <Link to={generateTrainingSlug(item)}>
                                <button>
                                  Voir l’annonce{" "}
                                  <img
                                    src={images.litleSearchIcone}
                                    alt="icone search"
                                    className="img-fluid icone-little-search"
                                  />
                                </button>
                              </Link>
                            </div>
                          </div>
                        </Link>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="MoblieCaroussel mobile">
              <Carousel itemsToShow={1}>
                <div className="col-lg-12 my-5 p-0">
                  <Link
                    to={generateTrainingSlug(item)}
                    title={item.title + " - " + item.agency?.name}
                  >
                    <div className="logoFormation">
                      <img
                        src={
                          mediaUrl(
                            {
                              url:
                                item?.agency?.logo?.url ||
                                item?.info_agency?.logo[0],
                            },
                            null,
                            "original"
                          ) || images.noimage
                        }
                        alt={item.title + " - " + item.agency?.name}
                        className="icone-logo icone-little-search"
                      />
                    </div>
                    <div className="formation-a-la-une">
                      <h3 className="titleFormation">
                        {item.title}
                        <span className="lepost"> </span>
                      </h3>
                      <ul className="infoFormation">
                        <li className="duree">{item.types[0]?.name || "NC"}</li>
                        <li className="lieux">
                          {showLocality2(item.region, item.cities)}
                        </li>
                      </ul>

                      <div className="btn-voir-formation">
                        <Link to={generateTrainingSlug(item)}>
                          <button>
                            Voir l’annonce{" "}
                            <img
                              src={images.litleSearchIcone}
                              alt="Voir l’annonce"
                              className="img-fluid icone-little-search"
                            />
                          </button>
                        </Link>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12 my-5 p-0">
                  <Link to={generateTrainingSlug(item)}>
                    <div className="logoFormation">
                      <img
                        src={
                          mediaUrl(
                            {
                              url:
                                item?.agency?.logo?.url ||
                                item?.info_agency?.logo[0],
                            },
                            null,
                            "original"
                          ) || images.noimage
                        }
                        alt={item.title}
                        className="icone-logo icone-little-search"
                      />
                    </div>
                    <div className="formation-a-la-une">
                      <h3 className="titleFormation">
                        {item.title}
                        <span className="lepost"> </span>
                      </h3>
                      <ul className="infoFormation">
                        <li className="duree">{item.types[0]?.name || "NC"}</li>
                        <li className="lieux">
                          {showLocality(item.region, item.cities)}
                        </li>
                      </ul>

                      <div className="btn-voir-formation">
                        <Link to={generateTrainingSlug(item)}>
                          <button>
                            Voir l’annonce{" "}
                            <img
                              src={images.litleSearchIcone}
                              alt="Voir l’annonce"
                              className="img-fluid icone-little-search"
                            />
                          </button>
                        </Link>
                      </div>
                    </div>
                  </Link>
                </div>
              </Carousel>
            </div>
          </section>
        )}

        <section className="listedesmocles py-5">
          <div className="container container-seformer text-center">
            <h2>
              <img
                src={images.iconechercheblanc}
                alt="Liste des mots clés"
                className="img-fluid icone-search"
              />{" "}
              Votre recherche :
            </h2>
            <div className="bloc-criteres">
              <div className="row listemotscle justify-content-center">
                {item.types[0]?.name && <span>{item.types[0].name}</span>}
                <span>{item.cities[0]?.name || "La Réunion"}</span>
                {item.sector?.name && <span>{item.sector.name}</span>}
                {item.level?.name && <span>{item.level.name}</span>}
                {/*<span>{trainingDuration(item.training_hours)}</span>*/}
              </div>
              <div className="row justify-content-center">
                <Link
                  to={`/annonces?city=${
                    (item.cities.length &&
                      item.cities[0].name &&
                      slugify(item.cities[0].name)) ||
                    ""
                  }&type=${
                    (item.types.length &&
                      item.types[0].name &&
                      slugify(item.types[0].name)) ||
                    ""
                  }&sector=${
                    (item.sector &&
                      item.sector.name &&
                      slugify(item.sector.name)) ||
                    ""
                  }&level=${
                    (item.level &&
                      item.level.name &&
                      slugify(item.level.name)) ||
                    ""
                  }`}
                >
                  <button>
                    Modifier ma recherche{" "}
                    <img
                      src={images.litleSearchIcone}
                      alt="icone search"
                      className="img-fluid icone-little-search"
                    />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Singleformations
