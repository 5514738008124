import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import SiteMetadata from "../componets/SiteMetadata/SiteMetadata"
import Header from "../componets/Header/Header"
import Footer from "../componets/Footer/Footer"
import Singleformations from "../componets/Singleformations/Singleformations"
import "../pages/globals.css"

export default function Home({ pageContext }) {
  const {
    item,
    relatedTrainings,
    levels,
    trainingLink,
    logo
  } = pageContext
  const metas = {
    title: item.title,
    metatitle: `${item.title} - Se Former.re`,
    description: item.description,
    keywords:
      "formation, stage, localité de l'annonce, formation à distance, formation en continu, professionnelle, en alternance",
  }

  return (
    <div className="pagesingleformation">
      <SiteMetadata metas={metas} />
      <Header logo={logo}/>
      <Singleformations
        item={item}
        relatedTrainings={relatedTrainings}
        levels={levels}
        trainingLink={trainingLink}
      />
      <Footer />
    </div>
  )
}
