import React, {useState} from 'react';
import images from '../../assets/images';
import './Singleformations.css';
import styled from "styled-components"
import Dropdown from "react-overlays/Dropdown"
import {useDropdownMenu, useDropdownToggle} from "react-overlays"
import ReCAPTCHA from "react-google-recaptcha";
import {Accordion, Button} from 'react-bootstrap'
import LoadingOverlay from 'react-loading-overlay';
import {mediaUrl} from "../../services/helpers";
const MenuContainer = styled("div")`
    display: ${(p) => (p.show ? "flex" : "none")};
    min-width: 150px;
    position: absolute;
    z-index: 1000;
    flex-direction: column;
    border: 1px solid #e5e5e5;
    background-color: white;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
`;

function PopupSingleFormation(props) {

    const {
        item,
        agency,
        levels,
        customerSituation,
        customerLevel,
        customerLastname,
        customerFirstname,
        customerPhone,
        customerEmail,
        customerContent,
        validPhone,
        validEmail,
        token,
        togglePopUp,
        handleUploadAttachement,
        handleDropdownFilter,
        handleFilter,
        setToken,
        sendEmail,
    } = props;
    const [stape, setStape] = useState(0);

    const processSendEmail = async (e) => {
        setLoading(true);
        const response = await sendEmail(e);
        if (response && response.status === 200) setStape(2);
        else setStape(3);
        setToken('');
        setLoading(false);
    };

    const situations = [
        {name: "Etudiant"},
        {name: "Salarié"},
        {name: "Professionnel"},
        {name: "Entrepreneur"},
        {name: "Demandeur d’emploi"},
    ];

    const Menu = ({role, items, onToggle, onChange}) => {
        const {show, props} = useDropdownMenu({
            flip: true,
            offset: [0, 8],
        });
        return (
            <MenuContainer {...props} role={role} show={show} items={items}>
                {items &&
                items.map((item, key) => {
                    return (
                        <button
                            key={"_" + key}
                            type="button"
                            onClick={() => {
                                onToggle && onToggle();
                                onChange && onChange(item)
                            }}
                            className="text-left hover:bg-brand-100 px-6 py-2"
                        >
                            {item.name}
                        </button>
                    )
                })}
            </MenuContainer>
        );
    };

    const Toggle = ({id, children}) => {
        const [props, {toggle}] = useDropdownToggle();
        return (
            <button
                type="button"
                className="btn btn-formation"
                id={id}
                {...props}
                onClick={toggle}
            >
                {children}
            </button>
        );
    };

    const DropdownButton = ({
                                show,
                                onToggle,
                                drop,
                                alignEnd,
                                title,
                                role,
                                items,
                                onChange,
                            }) => (
        <Dropdown
            show={show}
            onToggle={onToggle}
            drop={drop}
            alignEnd={alignEnd}
            itemSelector="button:not(:disabled)"
        >
            {({props}) => (
                <div {...props} className="relative inline-block contenu-recherch">
                    <Toggle id="example-toggle">{title}</Toggle>
                    <Menu
                        role={role}
                        items={items}
                        onChange={onChange}
                        onToggle={onToggle}
                    />
                </div>
            )}
        </Dropdown>
    );

    const ButtonToolbar = styled("div")`
        & > * + * {
            margin-left: 12px;
        }
    `;

    const [showSituation, setShowSituation] = useState(false);
    const [showLevel, setShowLevel] = useState(false);
    const [loading, setLoading] = useState(false);

    const closeAllMenu = () => {
        setShowSituation();
        setShowLevel();
    };

    return (
        <>
            <div className="Popup singleFormation" id="Modal" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header px-5">
                            <span><img src={images.logoSeformerPopup} alt='offres de formation et de stage à La Réunion'/></span>
                            <span className="mx-5 mt-3">avec</span>
                            <span className='span-image-logo'><img className="image-logo" src={mediaUrl(agency.logo, item.agency.logo, 'resize') || images.noimage} alt={item.agency?.name}/></span>
                            <span className='btnclose' onClick={togglePopUp}><img src={images.iconeFermer} className='imgclose'/></span>
                        </div>
                        <LoadingOverlay
                            active={loading}
                            className='sniperForm'
                            spinner
                            text='Envoi...'
                        >
                            <div className="modal-body px-105 pb-4 pt-0">
                                <form method="post" action="#">
                                    {stape === 0 && <div id="formStepOne">
                                        <div className="row my-3">
                                            <h3 className="Nomdanspopup">
                                                <span className="pourcentagepopup"><legend>50%</legend></span>Je
                                                m’informe<br className="showMobile"/> gratuitement et <br
                                                className="showMobile"/>
                                                sans engagement
                                            </h3>
                                        </div>
                                        <div className="row my-3">
                                            <div className="col-md-6 label">
                                                Situation
                                                <ButtonToolbar
                                                    className={
                                                        showSituation ? "dropdown-popup active" : "dropdown-popup"
                                                    }
                                                >
                                                    <DropdownButton
                                                        id={"dropdown_situation"}
                                                        show={showSituation}
                                                        items={situations}
                                                        onChange={e => {handleDropdownFilter(e, 'situation')}}
                                                        onToggle={nextShow => {
                                                            closeAllMenu();
                                                            setShowSituation(nextShow)
                                                        }}
                                                        title={customerSituation||'étudiant'}
                                                    />
                                                </ButtonToolbar>
                                            </div>
                                            <div className="col-md-6 label">
                                                Niveau actuel
                                                <ButtonToolbar
                                                    className={
                                                        showLevel ? "dropdown-popup active" : "dropdown-popup"
                                                    }
                                                >
                                                    <DropdownButton
                                                        id={"dropdown_level"}
                                                        show={showLevel}
                                                        items={levels}
                                                        onChange={e => {handleDropdownFilter(e, 'level')}}
                                                        onToggle={nextShow => {
                                                            closeAllMenu();
                                                            setShowLevel(nextShow)
                                                        }}
                                                        title={customerLevel||'BP - BAC'}
                                                    />
                                                </ButtonToolbar>
                                            </div>
                                        </div>

                                    <div className="row justify-content-end align-items-center btnpopup mx-2 my-2 pt-3">
                                        <Accordion.Toggle as={Button} variant="link" eventKey="0"
                                                          onClick={() => setStape(1)}
                                                          className="btn-send">Valider</Accordion.Toggle>
                                    </div>
                                    </div>
                                    }
                                    {
                                        stape === 1 && <div id="formStepTow">
                                            <div className="row my-3">
                                                <h3 className="Nomdanspopup">
                                                    <span className="pourcentagepopup">100%</span>Je m’informe<br
                                                    className="showMobile"/> gratuitement et <br className="showMobile"/>
                                                    sans engagement
                                                </h3>
                                            </div>
                                            <div className="row my-3">
                                                <label className="col-md-6">
                                                    Nom <span className="starForm">*</span>
                                                    <input type="text" name="customer_lastname" id="nom"
                                                           value={customerLastname} onChange={handleFilter}/>
                                                </label>
                                                <label className="col-md-6">
                                                    Prénom <span className="starForm">*</span>
                                                    <input type="text" name="customer_firstname" id="prenom"
                                                           value={customerFirstname} onChange={handleFilter}/>
                                                </label>
                                            </div>
                                            <div className="row my-3">
                                                <label className="col-md-6">
                                                    Téléphone <span className="starForm">*</span>
                                                    <input type="tel" name="customer_phone" id="phone" value={customerPhone}
                                                           onChange={handleFilter}
                                                           required/>
                                                    {!validPhone && <span className="msg_error ">Veuillez saisir un numéro de 10 chiffres.</span>}
                                                </label>
                                                <label className="col-md-6">
                                                    Email <span className="starForm">*</span>
                                                    <input type="email" name="customer_email" id="email"
                                                           value={customerEmail} onChange={handleFilter}/>
                                                    {!validEmail && <span className="msg_error ">Veuillez saisir une adresse e-mail valide.</span>}
                                                </label>
                                            </div>
                                            <div className="row my-3">
                                                 <label className="col-md-12">
                                                    Message
                                                    <textarea name="customer_content" id="content" onChange={handleFilter}>{customerContent}</textarea>
                                                </label>
                                            </div>
                                            <div className="row my-3">
                                                <label className="col-md-12">
                                                    Pièce jointe
                                                    <input type="file" name="customer_attachment" id="attachment" onChange={e => handleUploadAttachement(e)} />
                                                </label>
                                            </div>
                                            <div className="row my-3">
                                                <div className="col-md-6">
                                                    <span className="text-attention">ATTENTION : Renseignez bien votre numéro de téléphone.<br/>Le centre vous contactera de préférence par téléphone.</span>
                                                </div>
                                                <div className="col-md-6">
                                                    <ReCAPTCHA
                                                        sitekey="6Lfyk14UAAAAAKenWAADlaMizDYmWYLHui_TrGY9"
                                                        onChange={setToken}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="row justify-content-between align-items-center btnpopup mx-2 my-2 pt-3">
                                                <Accordion.Toggle as={Button} variant="link" onClick={() => setStape(0)}
                                                                  className="backbtn">
                                                    <img src={images.flechebackpopup} alt='fleche back popup'/> Revenir</Accordion.Toggle>
                                                <button onClick={(e) => processSendEmail(e)} className="btn-send"
                                                        type="submit"
                                                        disabled={!customerLastname || !customerFirstname || !customerPhone || !customerEmail || !validPhone || !validEmail || !token}>J’envoie
                                                    ma demande
                                                </button>
                                            </div>
                                        </div>
                                    }
                                    {
                                        stape === 2 && <div id="formStepFin">
                                            <div className="row my-3 justify-content-center">
                                                <h3 className="Nomdanspopup">
                                                    <span className="pourcentagepopup"><img src={images.checkpopup} alt='check pop-up'/></span><span
                                                    className="vertpopup">Votre message</span> a bien été envoyé !
                                                </h3>
                                            </div>
                                        </div>
                                    }
                                    {
                                        stape === 3 && <div id="formStepFin">
                                            <div className="row my-3 erreur justify-content-center">
                                                <h3 className="Nomdanspopup">
                                                    <span className="pourcentagepopup"><img
                                                        src={images.iconnotsend} alt='icon not send'/></span><span
                                                    className="vertpopup">Votre message</span> n'a pas pu être envoyé !
                                                </h3>
                                            </div>
                                        </div>
                                    }
                                </form>
                            </div>
                        </LoadingOverlay>
                    </div>
                </div>
                <div className="Fondpopup"> </div>
            </div>
        </>
    );
}

export default PopupSingleFormation;